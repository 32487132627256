<script>
export default {
  name: "HelexiumInput",
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    help: {
      type: String,
      default: "",
    },
    validations: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    state: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    initialValue: {
      default: "",
    },
  },
  mounted() {
    // let valueValidations = this.validations ? 1 : 0 // ? importante

    if (this.initialValue) {
      //Codigo redundante y mejorable
      if (this.type == "select") {
        if (typeof this.options[0] == "string") {
          let aux = this.options.find((option) => option == this.initialValue);
          this.fakeInputValue = aux;
          this.inputValue = aux;
        } else {
          const aux = this.options.find(
            (option) => option.value == this.initialValue
          );
          this.fakeInputValue = aux.label;
          this.inputValue = aux.value;
        }
      } else if (this.type == "date") {
        this.inputValue = new Date(this.initialValue)
          .toISOString()
          .substring(0, 10);
      } else {
        this.inputValue = this.initialValue;
      }
    }

    this.doValidations();
    this.$emit("singleValidation", {
      name: this.name,
      validation: this.validations ? this.errorMessages.length : 0,
    });
  },
  data() {
    return {
      inputValue: null,
      fakeInputValue: null,
      errorMessages: [],
      indexOption: -1,
    };
  },
  methods: {
    emitValue() {
      this.doValidations();
      let valueValidations = this.validations ? this.errorMessages.length : 0;
      this.$emit("singleValidation", {
        name: this.name,
        validation: valueValidations,
        value: this.inputValue,
      });

      if (this.type == "date") {
        const date = this.inputValue + "T00:00:00";
        this.$emit("input", date);
        return;
      }

      this.$emit("input", this.inputValue);
    },
    updateOption(option) {
      if (typeof option == "string") {
        this.inputValue = option;
        this.fakeInputValue = option;
      } else {
        this.fakeInputValue = option.label;
        this.inputValue = option.value;
      }

      this.emitValue();
    },
    doValidations() {
      this.errorMessages = [];

      //"required"
      if (
        this.validations.includes("required") &&
        !this.inputValue &&
        this.inputValue != 0 &&
        this.inputValue != false
      ) {
        this.errorMessages.unshift("Este campo es requerido");
      }

      //"email"
      if (this.validations.includes("email") && this.inputValue) {
        let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.inputValue)) {
          this.errorMessages.unshift("El email no es válido");
        }
      }

      if (this.validations.includes("length") && this.inputValue) {
        let lengthRegex = /length:\d+,\d+|length:\d+/;
        let length = this.validations
          .match(lengthRegex)[0]
          .split(":")[1]
          .split(",");
        if (length.length == 1) {
          if (this.inputValue.length < length[0]) {
            this.errorMessages.unshift(
              `Este campo debe tener al menos ${length[0]} caracteres`
            );
          }
        } else {
          if (
            this.inputValue.length < length[0] ||
            this.inputValue.length > length[1]
          ) {
            this.errorMessages.unshift(
              `Este campo debe tener entre ${length[0]} y ${length[1]} caracteres`
            );
          }
        }
      }
    },
    selectController(ev) {
      if (
        ev.key == "ArrowUp" ||
        ev.key == "ArrowDown" ||
        ev.key.toLowerCase() == "w" ||
        ev.key.toLowerCase() == "s"
      ) {
        if (ev.key == "ArrowDown" || ev.key.toLowerCase() == "s") {
          this.indexOption++;
          if (this.indexOption >= this.options.length) this.indexOption = 0;
        } else {
          this.indexOption--;
          if (this.indexOption < 0) this.indexOption = this.options.length - 1;
        }

        if (typeof this.options[0] == "string") {
          this.fakeInputValue = this.options[this.indexOption];
          this.inputValue = this.options[this.indexOption];
        } else {
          this.fakeInputValue = this.options[this.indexOption].label;
          this.inputValue = this.options[this.indexOption].value;
        }

        this.emitValue();
      }
    },
  },
};
</script>

<template>
  <div class="outer">
    <label :for="name">{{ label }}</label>
    <div class="inner">
      <input
        v-if="
          type === 'text' ||
          type === 'number' ||
          type === 'date' ||
          type === 'time' ||
          type === 'password'
        "
        @input="emitValue"
        :disabled="state == 'disabled'"
        :id="name"
        :type="type"
        class="input"
        v-model="inputValue"
        :placeholder="placeholder"
        :class="[state]"
      />

      <div v-else-if="type == 'select'" class="select" :class="[state]">
        <input
          class="input inputSelect"
          type="text"
          :id="name"
          :value="fakeInputValue"
          onkeypress="return false"
          @keydown="selectController"
          :disabled="state == 'disabled'"
        />
        <div class="options">
          <div
            v-for="(option, idx) in options"
            :key="name + idx"
            @click="updateOption(option)"
            class="option"
            :class="{ active: idx == indexOption }"
          >
            <template v-if="typeof option == 'string'">
              {{ option }}
            </template>
            <template v-else-if="typeof option == 'object'">
              {{ option.label || option.tipo }}
            </template>
          </div>
        </div>
      </div>

      <!-- correspondiente a text area con <overflow-y hidden, resize: none, y resizeauto </overflow->-->

      <!-- Falta un auto completador -->
    </div>
    <p class="help">{{ help }}</p>
    <div>
      <p class="message">{{ errorMessage }}</p>
    </div>
    <div class="messages" v-for="message in errorMessages" :key="message">
      <p class="message">{{ message }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../assets/scss/_variable";
* {
  box-sizing: border-box;
}

.outer {
  padding-bottom: 1rem;
}

.outer.disabled .input {
  background-color: #515e74;
}

.error.input {
  .dark &,
  & {
    border-color: rgb(242, 19, 67);
  }
}

.succes.input {
  .dark &,
  & {
    border-color: #1a5cff;
  }
}

.help,
.message {
  margin: 5px 0;
}
.message {
  color: $redSec;
}

/* General <<<<<<<<<<<<<<<<< */

.inner {
  position: relative;
}

.input,
.option {
  padding: 8px;
  outline: none;
  border: none;
  width: 100%;
  border: solid 0.5px $bordercolor;
  border-radius: 5px;
  .dark & {
    background: #18191c;
    color: aliceblue;
    border-color: $dark-border-color;
  }
}

.input:focus,
.option:hover,
.option.active {
  border: solid 0.5px $primary;
}

.input::placeholder {
  color: dimgrey;
}

.input:disabled {
  cursor: default;
}

.select {
  position: relative;
  width: 100%;
}

.inputSelect {
  caret-color: transparent;
  cursor: default;
}

.options {
  position: absolute;
  display: none;
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1000;
}

.inputSelect:focus + .options {
  display: block;
  opacity: 1;
}

.options:active {
  display: block;
}

.option {
  color: #18191c;
  cursor: default;
  .dark & {
    color: aliceblue;
  }
  &:hover {
    background-color: $primary !important;
    color: aliceblue;
  }
}
</style>
