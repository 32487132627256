var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outer"},[_c('label',{attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"inner"},[((_vm.type)==='checkbox'&&(
        _vm.type === 'text' ||
        _vm.type === 'number' ||
        _vm.type === 'date' ||
        _vm.type === 'time' ||
        _vm.type === 'password'
      ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"input",class:[_vm.state],attrs:{"disabled":_vm.state == 'disabled',"id":_vm.name,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"input":_vm.emitValue,"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}):((_vm.type)==='radio'&&(
        _vm.type === 'text' ||
        _vm.type === 'number' ||
        _vm.type === 'date' ||
        _vm.type === 'time' ||
        _vm.type === 'password'
      ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"input",class:[_vm.state],attrs:{"disabled":_vm.state == 'disabled',"id":_vm.name,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"input":_vm.emitValue,"change":function($event){_vm.inputValue=null}}}):(
        _vm.type === 'text' ||
        _vm.type === 'number' ||
        _vm.type === 'date' ||
        _vm.type === 'time' ||
        _vm.type === 'password'
      )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"input",class:[_vm.state],attrs:{"disabled":_vm.state == 'disabled',"id":_vm.name,"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.inputValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value},_vm.emitValue]}}):(_vm.type == 'select')?_c('div',{staticClass:"select",class:[_vm.state]},[_c('input',{staticClass:"input inputSelect",attrs:{"type":"text","id":_vm.name,"onkeypress":"return false","disabled":_vm.state == 'disabled'},domProps:{"value":_vm.fakeInputValue},on:{"keydown":_vm.selectController}}),_c('div',{staticClass:"options"},_vm._l((_vm.options),function(option,idx){return _c('div',{key:_vm.name + idx,staticClass:"option",class:{ active: idx == _vm.indexOption },on:{"click":function($event){return _vm.updateOption(option)}}},[(typeof option == 'string')?[_vm._v(" "+_vm._s(option)+" ")]:(typeof option == 'object')?[_vm._v(" "+_vm._s(option.label || option.tipo)+" ")]:_vm._e()],2)}),0)]):_vm._e()]),_c('p',{staticClass:"help"},[_vm._v(_vm._s(_vm.help))]),_c('div',[_c('p',{staticClass:"message"},[_vm._v(_vm._s(_vm.errorMessage))])]),_vm._l((_vm.errorMessages),function(message){return _c('div',{key:message,staticClass:"messages"},[_c('p',{staticClass:"message"},[_vm._v(_vm._s(message))])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }